document.addEventListener('DOMContentLoaded', function () {
    document.querySelectorAll('.uta-product-block').forEach(function (block) {
        const showMoreLink = block.querySelector('.show-more-merchants');
        const showLessLink = block.querySelector('.show-less-merchants');
        const hiddenMerchants = block.querySelectorAll('.uta-product-price-link.uta-product-hidden');

        if (showMoreLink && showLessLink) {
            showMoreLink.addEventListener('click', function (e) {
                e.preventDefault();
                hiddenMerchants.forEach(function (merchant) {
                    merchant.classList.remove('uta-product-hidden');
                });
                showMoreLink.classList.add('uta-product-hidden');
                showLessLink.classList.remove('uta-product-hidden');
            });

            showLessLink.addEventListener('click', function (e) {
                e.preventDefault();
                hiddenMerchants.forEach(function (merchant) {
                    merchant.classList.add('uta-product-hidden');
                });
                showMoreLink.classList.remove('uta-product-hidden');
                showLessLink.classList.add('uta-product-hidden');
            });
        }
    });
});